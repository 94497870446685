import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";
import { setUser, logoutUser } from "./redux/actions/authAction";
import Login from "./Pages/Login";
import Register from "./Pages/register";
import TheLayout from "./components/Layout/TheLayout";
import "./App.scss";
import { icons } from "./components/assets/icons";
import io from "socket.io-client";
const socket = io(`https://aws.abhayparimiti.cloudns.asia`, {
	transports: ["websocket"]
});
React.icons = icons;

// Check for token
if (localStorage.jwtToken) {
	// Set auth token header auth
	setAuthToken(localStorage.jwtToken);
	// Decode token and get user info and exp
	const decoded = jwt_decode(localStorage.jwtToken);
	// Set user and isAuthenticated
	store.dispatch(setUser(decoded));

	// Check for expired token
	const currentTime = Date.now() / 1000;
	if (decoded.exp < currentTime) {
		// Logout user
		store.dispatch(logoutUser());
		// Clear current Profile
		//store.dispatch(clearCurrentProfile());
		// Redirect to login
		window.location.href = "/";
	}
}
socket.on("alertspo2", async data => {
	console.log(data);
	if (
		localStorage.getItem("CIN") == data.CIN ||
		localStorage.getItem("_id") == data._id
	)
		new Notification(
			`Alert! ${data.name} has ${data.SPO2} SPO2 at ${data.time}   `
		);
});
function App() {
	if (!("Notification" in window)) {
		console.log("This browser does not support desktop notification");
	} else {
		Notification.requestPermission();
	}
	return (
		<Provider store={store}>
			<Router>
				<Switch>
					<Route exact path="/" component={Login} />
					<Route exact path="/register" component={Register} />
				</Switch>

				<Switch>
					<Route
						exact
						path="/schDashboard"
						name="Home"
						render={props => (
							<TheLayout {...props} socket={socket} />
						)}
					/>
					<Route
						exact
						path="/dashboard"
						name="Home"
						render={props => (
							<TheLayout {...props} socket={socket} />
						)}
					/>
					<Route
						exact
						path="/company/add"
						name="Add Company"
						render={props => (
							<TheLayout {...props} socket={socket} />
						)}
					/>
					<Route
						exact
						path="/company"
						name="Comapny"
						render={props => (
							<TheLayout {...props} socket={socket} />
						)}
					/>
					<Route
						exact
						path="/company/view"
						name="View Company"
						render={props => (
							<TheLayout {...props} socket={socket} />
						)}
					/>
					<Route
						exact
						path="/user/add"
						name="Add User"
						render={props => <TheLayout {...props} />}
						socket={socket}
					/>
					<Route
						exact
						path="/user/view"
						name="View User"
						render={props => <TheLayout {...props} />}
						socket={socket}
					/>
					<Route
						exact
						path="/user/info"
						name="View User"
						render={props => <TheLayout {...props} />}
						socket={socket}
					/>
					<Route
						exact
						path="/connection_status"
						name="Connection Status"
						render={props => (
							<TheLayout {...props} socket={socket} />
						)}
					/>
					<Route
						exact
						path="/dataanalytics"
						name="Data Analytics"
						render={props => (
							<TheLayout {...props} socket={socket} />
						)}
					/>
					<Route
						exact
						path="/search"
						name="search"
						render={props => <TheLayout {...props} />}
						socket={socket}
					/>
					<Route
						exact
						path="/attendance"
						name="Attendance"
						render={props => <TheLayout {...props} />}
						socket={socket}
					/>
					<Route
						exact
						path="/tabdata"
						name="Tabular data"
						render={props => <TheLayout {...props} />}
						socket={socket}
					/>
					<Route
						exact
						path="/userattendance"
						name="User Attendance"
						render={props => <TheLayout {...props} />}
						socket={socket}
					/>
					<Route
						exact
						path="/charts"
						name="Charts"
						render={props => <TheLayout {...props} />}
						socket={socket}
					/>
					<Route
						exact
						path="/user/deletedUsers"
						name="Deleted Users"
						render={props => <TheLayout {...props} />}
						socket={socket}
					/>
					<Route exact
						path="/user/reissuecard"
						name="Re-Issue Cards"
						render={props => <TheLayout {...props} />}
						socket={socket}
					/>
				</Switch>
			</Router>
		</Provider>
	);
}

export default App;
