import { baseUrl } from "../constant";
import { getUsersByDate } from "./user";

const apiList = {
	getAllProducts: `${baseUrl}/product/getall`,
	userRegistration: `${baseUrl}/auth/user/register`,
	adminRegistration: `${baseUrl}/auth/admin/register`,
	getCompanies: `${baseUrl}/company/getall`,
	getCompaniesByCIN: `${baseUrl}/company/getbyCIN/`,
	getCompany: `${baseUrl}/company/get/`,
	getCompanyByUID: `${baseUrl}/company/get/UID/`,
	editCompany: `${baseUrl}/company/edit/`,
	deleteCompany: `${baseUrl}/company/delete/`,
	getUserAll: `${baseUrl}/user/getall`,
	getUsersByCIN: `${baseUrl}/user/getForComp/`,
	getUserCountByCIN: `${baseUrl}/user/getForComp/count/`,
	getUserByComp: `${baseUrl}/user/get/bycompany/`,
	getUser: `${baseUrl}/user/get/`,
	editUser: `${baseUrl}/user/edit/`,
	deleteUser: `${baseUrl}/user/delete/`,
	userLogin: `${baseUrl}/auth/login`,
	userData: `${baseUrl}/userdata/`,
	search: `${baseUrl}/user/search/`,
	searchInAdmin: `${baseUrl}/user/searchInAdmin/`,
	getUsersByDate: `${baseUrl}/user/getForDate/`,
	getAttByDate: `${baseUrl}/userdata/`,
	getWorkingDays: `${baseUrl}/company/workingDays/`,
	getGuestUsersByCIN: `${baseUrl}/user/getGuestForComp/`,
	getGuestUserByComp: `${baseUrl}/user/get/GuestBycompany/`,
	irledUserData: `${baseUrl}/userdata/irled/`,
	getAttendance: `${baseUrl}/company/giveAttendance/`,
	removeUser: `${baseUrl}/user/removeUser/`,
	getRemovedUsers: `${baseUrl}/user/getremovedusers/`,
	getAvailableNFC: `${baseUrl}/user/getAvailableCards/`,
	restoreUser: `${baseUrl}/user/restoreUser/`,
	reIssueCard: `${baseUrl}/user/reissuecard`
};
export default apiList;
