import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getUser, editUser } from "../../services/user";
import Moment from "react-moment";
import moment from "moment";
import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CFormGroup,
  CInput,
  CLabel,
  CRow,
  CForm,
  CInvalidFeedback,
  CInputCheckbox,
  CModalFooter,
  CFormText,
  CTextarea,
  CInputRadio,
  CSelect
} from "@coreui/react";
import CIcon from "@coreui/icons-react";

class UserForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      middleName: "",
      lastName: "",
      company: "",
      dob: "",
      gender: "",
      bloodGroup: "",
      weight: "",
      height: "",
      mobile: 0,
      email: "",
      medHistory: false,
      diabetes2: false,
      diabetes1: false,
      hypertens: false,
      bpMed: false,
      thyroid: "",
      asthama: false,
      CAD: false,
      COPD: false,
      anemia: false,
      covid: false,
      subscription: false,
      NFC: "",
      companies: [],
      modal: false,
      errors: {},
      department: ''
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.user) this.setState({ ...nextProps.user })
    this.setState({ user: nextProps.userId, company: nextProps.companyId, NFC: nextProps.NFC, companies: nextProps.companies })
    // if(nextProps.user && nextProps.userId){
    //     this.setState({...nextProps.user, user: nextProps.userId})
    // }
    // if(nextProps.companyId && nextProps.NFC){
    //   this.state({company: nextProps.companyId, NFC: nextProps.NFC })
    // }
    // if(nextProps.companies) this.setState({companies: nextProps.companies});
  }

  onChange = e => {
    this.setState({
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value
    });
    this.setState({ errors: {} });
  };

  onSubmit = async (e) => {
    e.preventDefault();

    this.props.onSubmit(this.state);
  }

  render() {
    const { errors, companies } = this.state;
    const { Role } = this.props;
    return (
      <>
        <CForm onSubmit={this.onSubmit} className="form-horizontal">
          <CCardBody>
            <CFormGroup row>
              <CCol md="3">
                <CLabel htmlFor="text-input">Name</CLabel>
              </CCol>
              <CCol sm="4" md="3">
                <CInput
                  id="firstName"
                  name="firstName"
                  placeholder="first name"
                  value={this.state.firstName}
                  onChange={this.onChange}
                  invalid={errors.firstName} required />
                {errors.firstName && <CInvalidFeedback>{errors.firstName}</CInvalidFeedback>}
              </CCol>
              <CCol sm="4" md="3">
                <CInput id="middleName"
                  name="middleName"
                  placeholder="middle name"
                  value={this.state.middleName}
                  onChange={this.onChange}
                  invalid={errors.middleName} />
                {errors.middleName && <CInvalidFeedback>{errors.middleName}</CInvalidFeedback>}
              </CCol>
              <CCol sm="4" md="3">
                <CInput
                  id="lastName"
                  name="lastName"
                  placeholder="last name"
                  value={this.state.lastName}
                  onChange={this.onChange}
                  invalid={errors.lastName} required />
                {errors.lastName && <CInvalidFeedback>{errors.lastName}</CInvalidFeedback>}
              </CCol>

            </CFormGroup>
            {!this.props.forEdit && this.props.Role === 'superadmin' && <CFormGroup row>
              <CCol md="3">
                <CLabel htmlFor="select">Company</CLabel>
              </CCol>
              <CCol xs="12" md="9">
                <CSelect custom name="company" id="company" onChange={this.onChange.bind(this)} required>
                  <option value="0">Please select Device</option>
                  {/* <option value="1">Option #1</option>
                        <option value="2">Option #2</option>
                        <option value="3">Option #3</option> */}
                  {companies && companies.map(x => {
                    return (<option value={x._id}>{x.name} - {x.location}</option>)
                  })}
                  {this.props.Role === 'superadmin' && <CCol xs="9" md="4">
                    {/* <CSelect custom name="company" id="company" onChange={this.onChange.bind(this)} required> */}
                    {/* <option value="0">Please select Device</option>
                        <option value="1">Option #1</option>
                        <option value="2">Option #2</option>
                        <option value="3">Option #3</option> */}
                    {/* {companies && companies.map(x => {
                          return (<option value={x._id}>{x.name}</option>)
                        })} */}
                    {/* </CSelect> */}
                  </CCol>}
                </CSelect>
              </CCol>
            </CFormGroup>}
            <CFormGroup row>
              <CCol md="3">
                <CLabel htmlFor="date-input">Date of Birth</CLabel>
              </CCol>
              <CCol xs="12" md="9">
                <CInput type="date"
                  id="dob"
                  name="dob"
                  placeholder="DOB"
                  value={this.state.dob}
                  onChange={this.onChange}
                  invalid={errors.dob} />
                {errors.dob && <CInvalidFeedback>{errors.dob}</CInvalidFeedback>}
              </CCol>
            </CFormGroup>
            <CFormGroup row>
              <CCol md="3">
                <CLabel>Gender</CLabel>
              </CCol>
              <CCol md="9">
                <CFormGroup variant="custom-radio" inline>
                  <CInputRadio custom id="gender1" name="gender" value="male" onChange={this.onChange} />
                  <CLabel variant="custom-checkbox" htmlFor="gender1">Male</CLabel>
                </CFormGroup>
                <CFormGroup variant="custom-radio" inline>
                  <CInputRadio custom id="gender2" name="gender" value="female" onChange={this.onChange} />
                  <CLabel variant="custom-checkbox" htmlFor="gender2">Female</CLabel>
                </CFormGroup>
                <CFormGroup variant="custom-radio" inline>
                  <CInputRadio custom id="gender3" name="gender" value="other" onChange={this.onChange} />
                  <CLabel variant="custom-checkbox" htmlFor="gender3">Other</CLabel>
                </CFormGroup>
                {errors.gender && <CInvalidFeedback>{errors.gender}</CInvalidFeedback>}
              </CCol>
            </CFormGroup>
            {/* HEIGHT */}
            <CFormGroup row>
              <CCol md="3">
                <CLabel htmlFor="text-input">Height</CLabel>
              </CCol>
              <CCol sm="4">
                <CInput
                  type="text"
                  id="height"
                  name="height"
                  placeholder="Height in Inch"
                  value={this.state.height}
                  onChange={this.onChange}
                  invalid={errors.height} required />
                {errors.height && <CInvalidFeedback>{errors.height}</CInvalidFeedback>}
              </CCol>
            </CFormGroup>
            {/* Weight */}
            <CFormGroup row>
              <CCol md="3">
                <CLabel htmlFor="text-input">Weight</CLabel>
              </CCol>
              <CCol sm="4">
                <CInput type="text"
                  id="weight"
                  name="weight"
                  placeholder="weight in KG"
                  value={this.state.weight}
                  onChange={this.onChange}
                  invalid={errors.weight} required />
                {errors.weight && <CInvalidFeedback>{errors.weight}</CInvalidFeedback>}
              </CCol>
            </CFormGroup>
            {/* BLood GRoup */}
            <CFormGroup row>
              <CCol md="3">
                <CLabel htmlFor="text-input">Blood Group</CLabel>
              </CCol>
              <CCol sm="4">
                <CInput
                  type="text"
                  id="bloodGroup"
                  name="bloodGroup"
                  placeholder="Blood Group"
                  value={this.state.bloodGroup}
                  onChange={this.onChange}
                  invalid={errors.bloodGroup} required />
                {errors.bloodGroup && <CInvalidFeedback>{errors.bloodGroup}</CInvalidFeedback>}
                <CFormText className="help-block">ex:A+ use CAPITALS</CFormText>
              </CCol>
            </CFormGroup>
            <CFormGroup row>
              <CCol md="3">
                <CLabel htmlFor="email-input">Mobile Number</CLabel>
              </CCol>
              <CCol xs="12" md="9">
                <CInput
                  type="number"
                  id="mobile"
                  name="mobile"
                  placeholder="Enter Mobile +91 only"
                  value={this.state.mobile}
                  onChange={this.onChange}
                  invalid={errors.mobile} required />
                {errors.mobile && <CInvalidFeedback>{errors.mobile}</CInvalidFeedback>}

              </CCol>
            </CFormGroup><CFormGroup row>
              <CCol md="3">
                <CLabel htmlFor="email-input">Email</CLabel>
              </CCol>
              <CCol xs="12" md="9">
                <CInput type="email"
                  id="email"
                  name="email"
                  placeholder="Email"
                  value={this.state.email}
                  onChange={this.onChange}
                  invalid={errors.email} required />
                {errors.email && <CInvalidFeedback>{errors.email}</CInvalidFeedback>}
                <CFormText className="help-block">Please enter your email</CFormText>
              </CCol>
            </CFormGroup>
            <CFormGroup row>
              <CCol md="3">
                <CLabel htmlFor="textarea-input">Any Medical History</CLabel>
              </CCol>
              <CCol xs="12" md="9">
                <CFormGroup variant="custom-radio" inline>
                  <CInputRadio custom id="inline-radio-medHistory-1" name="medHistory" value={false} onChange={() => this.setState({ medHistory: true })} />
                  <CLabel variant="custom-checkbox" htmlFor="inline-radio-medHistory-1">Yes</CLabel>
                </CFormGroup>
                <CFormGroup variant="custom-radio" inline>
                  <CInputRadio custom id="inline-radio-medHistory-2" name="medHistory" value={true} onChange={() => this.setState({ medHistory: false })} />
                  <CLabel variant="custom-checkbox" htmlFor="inline-radio-medHistory-2">No</CLabel>
                </CFormGroup>
              </CCol>
            </CFormGroup>
            <CFormGroup row>
              {this.state.medHistory && (
                <CCol>
                  <CCol>
                    {/* <CCol> */}
                    <CFormGroup row>
                      <CCol md="6">
                        <CLabel htmlFor="textarea-input">Diabetes (Type 2)</CLabel>
                      </CCol>
                      <CCol xs="12" md="6">
                        <CFormGroup variant="custom-radio" inline>
                          <CInputRadio custom id="inline-radio-diabetes2-1" name="diabetes2" value={false} onChange={() => this.setState({ diabetes2: true })} />
                          <CLabel variant="custom-checkbox" htmlFor="inline-radio-diabetes2-1">Yes</CLabel>
                        </CFormGroup>
                        <CFormGroup variant="custom-radio" inline>
                          <CInputRadio custom id="inline-radio-diabetes2-2" name="diabetes2" value={true} onChange={() => this.setState({ diabetes2: false })} />
                          <CLabel variant="custom-checkbox" htmlFor="inline-radio-diabetes2-2">No</CLabel>
                        </CFormGroup>
                      </CCol>
                    </CFormGroup>
                    {/* </CCol> */}
                    {/* <CCol> */}
                    <CFormGroup row>
                      <CCol md="6">
                        <CLabel htmlFor="textarea-input">Diabetes (Type 1)</CLabel>
                      </CCol>
                      <CCol xs="12" md="6">
                        <CFormGroup variant="custom-radio" inline>
                          <CInputRadio custom id="inline-radio-diabetes1-1" name="diabetes1" value={false} onChange={() => this.setState({ diabetes1: true })} />
                          <CLabel variant="custom-checkbox" htmlFor="inline-radio-diabetes1-1">Yes</CLabel>
                        </CFormGroup>
                        <CFormGroup variant="custom-radio" inline>
                          <CInputRadio custom id="inline-radio-diabetes1-2" name="diabetes1" value={true} onChange={() => this.setState({ diabetes1: false })} />
                          <CLabel variant="custom-checkbox" htmlFor="inline-radio-diabetes1-2">No</CLabel>
                        </CFormGroup>
                      </CCol>
                    </CFormGroup>
                    {/* </CCol> */}
                    <CFormGroup row>
                      <CCol md="6">
                        <CLabel htmlFor="textarea-input">Hypertension (High BP)</CLabel>
                      </CCol>
                      <CCol xs="12" md="6">
                        <CFormGroup variant="custom-radio" inline>
                          <CInputRadio custom id="inline-radio-hypertens-1" name="hypertens" value={false} onChange={() => this.setState({ hypertens: true })} />
                          <CLabel variant="custom-checkbox" htmlFor="inline-radio-hypertens-1">Yes</CLabel>
                        </CFormGroup>
                        <CFormGroup variant="custom-radio" inline>
                          <CInputRadio custom id="inline-radio-hypertens-2" name="hypertens" value={true} onChange={() => this.setState({ hypertens: false })} />
                          <CLabel variant="custom-checkbox" htmlFor="inline-radio-hypertens-2">No</CLabel>
                        </CFormGroup>
                      </CCol>
                    </CFormGroup>
                    {this.state.hypertens && (
                      <CCol>
                        <CFormGroup row>
                          <CCol md="6">
                            <CLabel htmlFor="textarea-input">On BP Medicine</CLabel>
                          </CCol>
                          <CCol xs="12" md="6">
                            <CFormGroup variant="custom-radio" inline>
                              <CInputRadio custom id="inline-radio-bpMed-1" name="bpMed" value={false} onChange={() => this.setState({ bpMed: true })} />
                              <CLabel variant="custom-checkbox" htmlFor="inline-radio-bpMed-1">Yes</CLabel>
                            </CFormGroup>
                            <CFormGroup variant="custom-radio" inline>
                              <CInputRadio custom id="inline-radio-bpMed-2" name="bpMed" value={true} onChange={() => this.setState({ bpMed: false })} />
                              <CLabel variant="custom-checkbox" htmlFor="inline-radio-bpMed-2">No</CLabel>
                            </CFormGroup>
                          </CCol>
                        </CFormGroup>
                      </CCol>
                    )}
                    <CFormGroup row>
                      <CCol md="3">
                        <CLabel htmlFor="textarea-input">Thyroid</CLabel>
                      </CCol>
                      <CCol xs="12" md="9">
                        <CFormGroup variant="custom-radio" inline>
                          <CInputRadio custom id="inline-radio-thyroid-1" name="thyroid" value={false} onChange={() => this.setState({ thyroid: "underactive" })} />
                          <CLabel variant="custom-checkbox" htmlFor="inline-radio-thyroid-1">Underactive</CLabel>
                        </CFormGroup>
                        <CFormGroup variant="custom-radio" inline>
                          <CInputRadio custom id="inline-radio-thyroid-2" name="thyroid" value={true} onChange={() => this.setState({ thyroid: "hyperactive" })} />
                          <CLabel variant="custom-checkbox" htmlFor="inline-radio-thyroid-2">Hyperactive</CLabel>
                        </CFormGroup>
                        <CFormGroup variant="custom-radio" inline>
                          <CInputRadio custom id="inline-radio-thyroid-3" name="thyroid" value={true} onChange={() => this.setState({ thyroid: "normal" })} />
                          <CLabel variant="custom-checkbox" htmlFor="inline-radio-thyroid-3">Normal</CLabel>
                        </CFormGroup>
                      </CCol>
                    </CFormGroup>

                    <CFormGroup row>
                      <CCol md="6">
                        <CLabel htmlFor="textarea-input">Asthama</CLabel>
                      </CCol>
                      <CCol xs="12" md="6">
                        <CFormGroup variant="custom-radio" inline>
                          <CInputRadio custom id="inline-radio-asthama-1" name="asthama" value={false} onChange={() => this.setState({ asthama: true })} />
                          <CLabel variant="custom-checkbox" htmlFor="inline-radio-asthama-1">Yes</CLabel>
                        </CFormGroup>
                        <CFormGroup variant="custom-radio" inline>
                          <CInputRadio custom id="inline-radio-asthama-2" name="asthama" value={true} onChange={() => this.setState({ asthama: false })} />
                          <CLabel variant="custom-checkbox" htmlFor="inline-radio-asthama-2">No</CLabel>
                        </CFormGroup>
                      </CCol>
                    </CFormGroup>

                    <CFormGroup row>
                      <CCol md="6">
                        <CLabel htmlFor="textarea-input">Coronary Artery Disease</CLabel>
                      </CCol>
                      <CCol xs="12" md="6">
                        <CFormGroup variant="custom-radio" inline>
                          <CInputRadio custom id="inline-radio-CAD-1" name="CAD" value={false} onChange={() => this.setState({ CAD: true })} />
                          <CLabel variant="custom-checkbox" htmlFor="inline-radio-CAD-1">Yes</CLabel>
                        </CFormGroup>
                        <CFormGroup variant="custom-radio" inline>
                          <CInputRadio custom id="inline-radio-CAD-2" name="CAD" value={true} onChange={() => this.setState({ CAD: false })} />
                          <CLabel variant="custom-checkbox" htmlFor="inline-radio-CAD-2">No</CLabel>
                        </CFormGroup>
                      </CCol>
                    </CFormGroup>

                    <CFormGroup row>
                      <CCol md="6">
                        <CLabel htmlFor="textarea-input">Chronic Obstructive Pulmonary Disorder</CLabel>
                      </CCol>
                      <CCol xs="12" md="6">
                        <CFormGroup variant="custom-radio" inline>
                          <CInputRadio custom id="inline-radio-COPD-1" name="COPD" value={false} onChange={() => this.setState({ COPD: true })} />
                          <CLabel variant="custom-checkbox" htmlFor="inline-radio-COPD-1">Yes</CLabel>
                        </CFormGroup>
                        <CFormGroup variant="custom-radio" inline>
                          <CInputRadio custom id="inline-radio-COPD-2" name="COPD" value={true} onChange={() => this.setState({ COPD: false })} />
                          <CLabel variant="custom-checkbox" htmlFor="inline-radio-COPD-2">No</CLabel>
                        </CFormGroup>
                      </CCol>
                    </CFormGroup>


                    <CFormGroup row>
                      <CCol md="6">
                        <CLabel htmlFor="textarea-input">Anemia</CLabel>
                      </CCol>
                      <CCol xs="12" md="6">
                        <CFormGroup variant="custom-radio" inline>
                          <CInputRadio custom id="inline-radio-anemia-1" name="anemia" value={false} onChange={() => this.setState({ anemia: true })} />
                          <CLabel variant="custom-checkbox" htmlFor="inline-radio-anemia-1">Yes</CLabel>
                        </CFormGroup>
                        <CFormGroup variant="custom-radio" inline>
                          <CInputRadio custom id="inline-radio-anemia-2" name="anemia" value={true} onChange={() => this.setState({ anemia: false })} />
                          <CLabel variant="custom-checkbox" htmlFor="inline-radio-anemia-2">No</CLabel>
                        </CFormGroup>
                      </CCol>
                    </CFormGroup>


                    <CFormGroup row>
                      <CCol md="6">
                        <CLabel htmlFor="textarea-input">COVID-19</CLabel>
                      </CCol>
                      <CCol xs="12" md="6">
                        <CFormGroup variant="custom-radio" inline>
                          <CInputRadio custom id="inline-radio-covid-1" name="covid" value={false} onChange={() => this.setState({ covid: true })} />
                          <CLabel variant="custom-checkbox" htmlFor="inline-radio-covid-1">Yes</CLabel>
                        </CFormGroup>
                        <CFormGroup variant="custom-radio" inline>
                          <CInputRadio custom id="inline-radio-covid-2" name="covid" value={true} onChange={() => this.setState({ covid: false })} />
                          <CLabel variant="custom-checkbox" htmlFor="inline-radio-covid-2">No</CLabel>
                        </CFormGroup>
                      </CCol>
                    </CFormGroup>
                  </CCol>
                </CCol>
              )}
            </CFormGroup>
            <CFormGroup row>
              <CCol md="3">
                <CLabel htmlFor="email-input">Unique Id</CLabel>
              </CCol>
              <CCol xs="12" md="9">

                <CInput
                  placeholder="Add NFC"
                  name="NFC"

                  value={this.state.NFC}
                  onChange={this.onChange}
                  className="mb-3"
                  invalid={errors.NFC} />
                {errors.NFC && <CInvalidFeedback>{errors.NFC}</CInvalidFeedback>}
              </CCol>
            </CFormGroup>
            <CFormGroup row>
              <CCol md="3">
                <CLabel htmlFor="email-input">Department</CLabel>
              </CCol>
              <CCol xs="12" md="9">


                <CInput
                  placeholder="Add Department"
                  name="department"
                  value={this.state.department}
                  onChange={this.onChange}
                  className="mb-3"
                  invalid={errors.department} />
                {errors.department && <CInvalidFeedback>{errors.department}</CInvalidFeedback>}
              </CCol>
            </CFormGroup>
            <CFormGroup row>
              <CCol md="3">
                <CLabel></CLabel>
              </CCol>
            </CFormGroup>
          </CCardBody>
          <CCardFooter>
            <CButton type="submit" size="sm" color="primary" className="ml-3"><CIcon name="cil-scrubber" /> Submit</CButton>
            <CButton type="reset" size="sm" color="danger" className="ml-3" ><CIcon name="cil-ban" /> Reset</CButton>
          </CCardFooter>
        </CForm>
      </>
    );
  }
}

export default UserForm;