export default [
	{
		_tag: "CSidebarNavItem",
		name: "Basic Info",
		to: "/user/info",
		icon: "cil-user"
	},
	{
		_tag: "CSidebarNavItem",
		name: "Charts",
		to: "/charts",
		icon: "cil-chart-pie"
	},
	{
		_tag: "CSidebarNavItem",
		name: "Tabular data",
		to: "/tabdata",
		icon: "cil-input-power"
	},
	{
		_tag: "CSidebarNavItem",
		name: "Attendance",
		to: "/userattendance",
		icon: "cil-envelope-letter"
	},

	{
		_tag: "CSidebarNavDivider",
		className: "m-2"
	}
];
