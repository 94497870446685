export default [
	{
		_tag: "CSidebarNavDropdown",
		name: "Manage Users",
		route: "/base",
		icon: "cil-puzzle",
		_children: [
			{
				_tag: "CSidebarNavItem",
				name: "Add User",
				to: "/user/add"
			},
			{
				_tag: "CSidebarNavItem",
				name: "View/Edit Users",
				to: "/user/view"
			}
		]
	},
	{
		_tag: "CSidebarNavItem",
		name: "Attendence",
		to: "/charts",
		icon: "cil-chart-pie"
	},
	{
		_tag: "CSidebarNavItem",
		name: "Health Data",
		to: "/charts",
		icon: "cil-heart"
	},
	{
		_tag: "CSidebarNavItem",
		name: "search user",
		to: "/charts",
		icon: "cil-magnifying-glass"
	},
	{
		_tag: "CSidebarNavDivider",
		className: "m-2"
	}
];
