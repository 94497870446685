import React, { useState, useEffect } from "react";
import {
	TheContent,
	TheSidebar,
	// TheFooter,
	TheHeader
} from "./index";
import { CModal, CModalHeader, CModalTitle, CModalBody } from "@coreui/react";
import AddUser from "../AddUser";
const TheLayout = props => {
	const [Role, setRole] = useState("");
	const [show, setShow] = useState(true);
	const [userData, SetuserData] = useState();
	const [modal, setModal] = useState(false);
	const sidenav = () => {
		if (show) {
			setShow(false);
		} else {
			setShow(true);
		}
	};
	useEffect(() => {
		if (props.socket) {
			props.socket.on("newUser", async data => {
				setModal(true);
				SetuserData(data);
			});
		}
	});

	return (
		<div className="c-app c-default-layout">
			<TheSidebar Role={Role} show={show} />
			<div className="c-wrapper">
				<TheHeader sidenav={sidenav} />
				<div className="c-body">
					<TheContent
						Role={Role}
						setRole={setRole}
						socket={props.socket}
					/>
				</div>
				{/* <TheFooter/> */}
			</div>
			<CModal show={modal} onClose={() => setModal(!modal)} size="lg">
				<CModalHeader closeButton>
					<CModalTitle>Add User</CModalTitle>
				</CModalHeader>
				<CModalBody>
					<AddUser
						data={userData}
						Role={Role}
						close={() => setModal(!modal)}
					/>
				</CModalBody>
			</CModal>
		</div>
	);
};

export default TheLayout;
