import React from "react";
// import { DeletedUsers } from "./components/DeleteUsers";
// import TheLayout from "./components/Layout/TheLayout"
const Dashboard = React.lazy(() => import("./Pages/dashboard/Dashboard"));
const SchoolDashboard = React.lazy(() =>
	import("./Pages/dashboard/SchoolDashboard")
);

const AddCompany = React.lazy(() => import("./components/AddCompany"));
const ViewCompany = React.lazy(() => import("./components/ViewCompany"));
const AddUser = React.lazy(() => import("./components/AddUser"));
const ViewUser = React.lazy(() => import("./components/ViewUser"));
const UserInfo = React.lazy(() => import("./components/UserInfo"));
const ConStatus = React.lazy(() => import("./components/ConStatus"));
const DataAnalytics = React.lazy(() => import("./components/DataAnalytics"));
const Search = React.lazy(() => import("./components/search"));
const Attendance = React.lazy(() => import("./components/Attendance"));
const TabData = React.lazy(() => import("./components/TabData"));
const UserAtt2 = React.lazy(() => import("./components/useratt2"));
const Charts = React.lazy(() => import("./components/charts/Charts"));
const DeletedUsers = React.lazy(() => import("./components/DeletedUsers"));
const ReissueCard = React.lazy(() => import("./components/ReissueCard"))
const routes = [
	{ path: "/", exact: true, name: "Home" },
	{ path: "/dashboard", name: "Dashboard", component: Dashboard },
	{ path: "/schDashboard", name: "SchDashboard", component: SchoolDashboard },
	{ path: "/company/add", name: "Add Company", component: AddCompany },
	{ path: "/user/add", name: "Add User", component: AddUser },
	{ path: "/company/view", name: "View Company", component: ViewCompany },
	{ path: "/user/view", name: "View User", component: ViewUser },
	{ path: "/user/info", name: "User Info", component: UserInfo },
	{
		path: "/connection_status",
		name: "Connection Status",
		component: ConStatus
	},
	{
		path: "/dataanalytics",
		name: "Data Analystics",
		component: DataAnalytics
	},
	{ path: "/search", name: "Search", component: Search },
	{ path: "/attendance", name: "Attendance", component: Attendance },
	{ path: "/tabdata", name: "Tabular Data", component: TabData },
	{ path: "/userattendance", name: "User Attendance", component: UserAtt2 },
	{ path: "/charts", name: "Charts", component: Charts },
	{path: "/user/deletedUsers", name: "Deleted Users", component: DeletedUsers},
	{path: "/user/reissuecard", name: "Re-issue Cards", component: ReissueCard}
];

export default routes;
