export default [
	{
		_tag: "CSidebarNavDropdown",
		name: "Manage Devices",
		icon: "cil-puzzle",
		_children: [
			{
				_tag: "CSidebarNavItem",
				name: "Add Device",
				to: "/company/add"
			},
			{
				_tag: "CSidebarNavItem",
				name: "View/Edit Device Info",
				to: "/company/view"
			},
			{
				_tag: "CSidebarNavItem",
				name: "Add User",
				to: "/user/add"
			},
			{
				_tag: "CSidebarNavItem",
				name: "View/Edit Users",
				to: "/user/view"
			},
			{
				_tag: "CSidebarNavItem",
				name: "Deleted Users",
				to: "/user/deletedUsers"
			},
			{
				_tag: "CSidebarNavItem",
				name: "Re-Issue Card",
				to: "/user/reissuecard"
			}
		]
	},
	{
		_tag: "CSidebarNavItem",
		name: "Data Analytics",
		to: "/dataanalytics",
		icon: "cil-chart-pie"
	},
	{
		_tag: "CSidebarNavItem",
		name: "hardware connection status",
		to: "/connection_status",
		icon: "cil-input-power"
	},
	{
		_tag: "CSidebarNavItem",
		name: "search user",
		to: "/search",
		icon: "cil-magnifying-glass"
	},
	{
		_tag: "CSidebarNavItem",
		name: "Attendance",
		to: "/attendance",
		icon: "cil-chart-pie"
	},

	{
		_tag: "CSidebarNavDivider",
		className: "m-2"
	}
];
