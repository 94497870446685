import React, { useState } from "react";
//import { useSelector, useDispatch } from 'react-redux'
import {
	CCreateElement,
	CSidebar,
	CSidebarBrand,
	CSidebarNav,
	CSidebarNavDivider,
	CSidebarNavTitle,
	CSidebarMinimizer,
	CSidebarNavDropdown,
	CSidebarNavItem
} from "@coreui/react";

// sidebar nav config
import superadmin from "./_supernav";
import admin from "./_adminnav";
import user from "./_usernav";

const TheSidebar = ({ Role, show }) => {
	// const dispatch = useDispatch()
	// const show = useSelector(state => state.sidebarShow)

	const [RoleList] = useState({ superadmin, admin, user });

	return (
		<CSidebar
			show={show}
			// onShowChange={(val) => dispatch({type: 'set', sidebarShow: val })}
		>
			<CSidebarBrand className="d-md-down-none" to="/">
				{/* <CIcon
          className="c-sidebar-brand-full"
          name="logo-negative"
          height={35}
        />
        <CIcon
          className="c-sidebar-brand-minimized"
          name="sygnet"
          height={35}
        /> */}
			</CSidebarBrand>
			<CSidebarNav>
				<CCreateElement
					items={RoleList[Role]}
					components={{
						CSidebarNavDivider,
						CSidebarNavDropdown,
						CSidebarNavItem,
						CSidebarNavTitle
					}}
				/>
			</CSidebarNav>
			<CSidebarMinimizer className="c-d-md-down-none" />
		</CSidebar>
	);
};

export default React.memo(TheSidebar);
