import axios from "axios";
import apiList from "./apilist";

export const getCompanies = () => axios.get(apiList.getCompanies);
export const getCompany = id => axios.get(apiList.getCompany + id);
export const getCompanyByUID = id => axios.get(apiList.getCompanyByUID + id);
export const editCompany = (id, userData) =>
	axios.post(apiList.editCompany + id, userData);
export const deleteCompany = id => axios.post(apiList.deleteCompany + id);
export const getCompaniesByCIN = CIN =>
	axios.get(apiList.getCompaniesByCIN + CIN);
export const getAttendance = CIN => axios.get(apiList.getAttendance + CIN);
