import React from "react";
//import { useSelector, useDispatch } from 'react-redux'
import {
	CHeader,
	CToggler,
	//CHeaderBrand,
	CHeaderNav,
	CHeaderNavItem,
	CHeaderNavLink,
	CSubheader,
	CBreadcrumbRouter,
	CLink
} from "@coreui/react";
import CIcon from "@coreui/icons-react";

// routes config
import routes from "../../routes";

import {
	TheHeaderDropdown,
	TheHeaderDropdownMssg,
	TheHeaderDropdownNotif,
	TheHeaderDropdownTasks
} from "./index";

const TheHeader = ({ setRole, sidenav }) => {
	// const dispatch = useDispatch()
	// const sidebarShow = useSelector(state => state.sidebarShow)

	// const toggleSidebar = () => {
	//   const val = [true, 'responsive'].includes(sidebarShow) ? false : 'responsive'
	//   dispatch({type: 'set', sidebarShow: val})
	// }

	// const toggleSidebarMobile = () => {
	//   const val = [false, 'responsive'].includes(sidebarShow) ? true : 'responsive'
	//   dispatch({type: 'set', sidebarShow: val})
	// }

	return (
		<CHeader withSubheader>
			<CToggler
				inHeader
				className="ml-md-3 d-lg-none"
				onClick={() => sidenav()}
			/>
			<CToggler
				inHeader
				className="ml-3 d-md-down-none"
				onClick={() => sidenav()}
			/>
			{/* <CHeaderBrand className="mx-auto d-lg-none" to="/">
        <CIcon name="logo" height="48" alt="Logo"/>
      </CHeaderBrand> */}

			<CHeaderNav className="mr-auto">
				{/* <CHeaderNavItem className="px-3" >
          <CHeaderNavLink onClick={()=>setRole("superadmin")}>SuperAdmin</CHeaderNavLink>
        </CHeaderNavItem>
        <CHeaderNavItem  className="px-3">
          <CHeaderNavLink onClick={()=>setRole("admin")}>Admin</CHeaderNavLink>
        </CHeaderNavItem>
        <CHeaderNavItem className="px-3">
          <CHeaderNavLink onClick={()=>setRole("user")}>User</CHeaderNavLink>
        </CHeaderNavItem> */}
			</CHeaderNav>

			<CHeaderNav className="px-3">
				<TheHeaderDropdownNotif />
				{/* <TheHeaderDropdownTasks/>
        <TheHeaderDropdownMssg/> */}
				<TheHeaderDropdown />
			</CHeaderNav>

			<CSubheader className="px-3 justify-content-between">
				<CBreadcrumbRouter
					className="border-0 c-subheader-nav m-0 px-0 px-md-3"
					routes={routes}
				/>
				<div className="d-md-down-none mfe-2 c-subheader-nav">
					{/* <CLink className="c-subheader-nav-link"href="#">
              <CIcon name="cil-speech" alt="Settings" />
            </CLink>
            <CLink 
              className="c-subheader-nav-link" 
              aria-current="page" 
              to="/dashboard"
            >
              <CIcon name="cil-graph" alt="Dashboard" />&nbsp;Dashboard
            </CLink>
            <CLink className="c-subheader-nav-link" href="#">
              <CIcon name="cil-settings" alt="Settings" />&nbsp;Settings
            </CLink> */}
				</div>
			</CSubheader>
		</CHeader>
	);
};

export default TheHeader;
