import axios from "axios";
import apiList from "./apilist";

export const getUserAll = () => axios.get(apiList.getUserAll);
export const getUserByComp = company =>
	axios.get(apiList.getUserByComp + company);
export const getUser = id => axios.get(apiList.getUser + id);
export const deleteUser = id => axios.post(apiList.deleteUser + id);
export const userData = id => axios.get(apiList.userData + id);
export const editUser = (id, userData) =>
	axios.post(apiList.editUser + id, userData);
export const search = key => axios.get(apiList.search + key);
export const getUsersByCIN = CIN => axios.get(apiList.getUsersByCIN + CIN);
export const getUserCountByCIN = CIN =>
	axios.get(apiList.getUserCountByCIN + CIN);
export const searchInAdmin = (CIN, key) =>
	axios.post(apiList.searchInAdmin + key, { CIN: CIN });
export const getUsersByDate = (CIN, date) =>
	axios.post(apiList.getUsersByDate + date, { CIN });
export const getAttByDate = (id, month) =>
	axios.post(apiList.getAttByDate + id, { month });
export const getWorkingDays1 = (month, CIN, days) =>
	axios.post(apiList.getWorkingDays + month, { CIN, days });
export const getWorkingDays = (month, CIN) =>
	axios.post(apiList.getWorkingDays + month, { CIN });
export const getGuestUsersByCIN = CIN =>
	axios.get(apiList.getGuestUsersByCIN + CIN);
export const getGuestUserByComp = company =>
	axios.get(apiList.getGuestUserByComp + company);
export const irledUserData = id => axios.get(apiList.irledUserData + id);
export const removeUser = NFC => axios.post(apiList.removeUser, { NFC: NFC })
export const getRemovedUsers = companyId => axios.get(apiList.getRemovedUsers + companyId)
export const getAvailableCards = companyId => axios.get(apiList.getAvailableNFC + companyId)
export const restoreUser = ({ userId, NFC }) => axios.post(apiList.restoreUser, { userId, NFC })
export const reIssueCard = (userData) => axios.post(apiList.reIssueCard, { ...userData });