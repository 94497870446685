import { SET_USER, UNSET_USER, SET_ERRORS } from "./types";
import setAuthToken from "../../utils/setAuthToken";
import {
	registerUser,
	registerAdmin,
	loginUser,
	updateUserDetails
} from "../../services/auth";
import jwt_decode from "jwt-decode";
// Login - Get User Token also CIN for admin
export const logUser = userData => dispatch => {
	loginUser(userData)
		.then(res => {
			console.log(res);
			// Save to localStorage
			const { token } = res.data;
			const { CIN } = res.data.user;
			const { _id } = res.data.user;
			// Set token to ls
			localStorage.setItem("jwtToken", token);
			localStorage.setItem("CIN", CIN);
			localStorage.setItem("_id", _id);

			// Set token to Auth header
			setAuthToken(token);
			// Decode token to get user data
			// const decoded = jwt_decode(token);
			// Set current user
			// delete res.data.user.password;
			return dispatch(setUser(res.data.user));
		})
		.catch(err => {
			// console.log(err);
			if (err.response.data) {
				dispatch({
					type: SET_ERRORS,
					payload: err.response.data
				});
			}
		});
};
export const regAdmin = userData => dispatch => {
	registerAdmin(userData)
		.then(res => {
			return res.data;
		})
		.catch(err => {
			console.log({ err });
			if (err.response.data) {
				dispatch({
					type: SET_ERRORS,
					payload: err.response.data
				});
			}
		});
};
export const regUser = userData => dispatch => {
	registerUser(userData)
		.then(res => {
			console.log(res);
			// Save to localStorage
			const { token } = res.data;
			const { CIN } = res.data.user;
			const { _id } = res.data.user;
			// Set token to ls
			// localStorage.setItem("jwtToken", token);
			// localStorage.setItem("CIN", CIN);
			
			//to prevent the user from changing from admin to noraml user
			//this happens when you add user, your layout changes from one for admin to one for normal user
			//to prevent commented it out	
			// localStorage.setItem("_id", _id);

			// Set token to Auth header
			// setAuthToken(token);
			// Decode token to get user data
			// const decoded = jwt_decode(token);
			// Set current user
			// delete res.data.user.password;
			// return dispatch(setUser(res.data.user));
		})
		.catch(err => {
			console.log(err);
			if (err.response.data) {
				dispatch({
					type: SET_ERRORS,
					payload: err.response.data
				});
			}
		});
};

export const updateUser = userData => dispatch => {
	updateUserDetails(userData)
		.then(res => {
			delete res.data.data.password;
			return dispatch(setUser(res.data.data));
		})
		.catch(err => {
			console.log({ err });
			if (err.response.data) {
				dispatch({
					type: SET_ERRORS,
					payload: err.response.data
				});
			}
		});
};
// Set logged in user
export const setUser = decoded => {
	return {
		type: SET_USER,
		payload: decoded
	};
};

// Log user out
export const logoutUser = () => dispatch => {
	console.log("logout");
	// Remove token from localStorage
	localStorage.removeItem("jwtToken");

	// Remove auth header for future requests
	setAuthToken(false);
	// Set current user to {} which will set isAuthenticated to false
	dispatch({
		type: UNSET_USER,
		payload: {}
	});
};
