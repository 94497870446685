/**
 **TODO**
 - [ ] add Valditionu 
 - [x] Handle Submission
 * */ 
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from "prop-types"
import { regUser } from "../redux/actions/authAction"
import { getCompaniesByCIN } from "../services/company"
import UserForm from './forms/UserForm'

import {CCol, CRow, CCard, CCardHeader} from "@coreui/react"

class AddUser extends Component {
  constructor(props) {
    super(props)
    this.state = {
      errors: {}
    }
  }
  componentDidMount() {
    getCompaniesByCIN(localStorage.getItem('CIN')).then(data => {
      this.setState({ companies: data.data.devices });
      //  console.log(data.data.devices);
    }).catch(err => {
      console.log(err);
    })
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
    if(nextProps.data){
      console.log(nextProps.data,this.state.companies);
     let index =  this.state.companies.map(z=>z.abhayUID).indexOf(nextProps.data.MMID);
     this.setState({company:this.state.companies[index]._id,NFC:nextProps.data.CID,modal:true});
    }
  }
  onSubmit = async (data) => {
    console.log("DATA ", data)
    //everything from state except companies, errors, modal, subscription
    let userData = {...data}
   delete userData["companies"]
   delete userData["errors"]
   delete userData["modal"]
 
    this.props.regUser(userData);

  }
  
  render(){
    return (
     <CRow className="justify-content-center">
          <CCol xs="12" md={this.state.modal ? "12":"6"}>
            <CCard>
              <CCardHeader>Add User </CCardHeader>
        <UserForm  Role={this.props.Role} forEdit={false} onSubmit={this.onSubmit} companies={this.state.companies} companyId={this.state.company} NFC={this.state.NFC} />
              </CCard>
      </CCol>
      </CRow>
    )
  }
}

AddUser.propTypes = {

  regUser: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired
};
const mapStateToProps = (state) => ({

  errors: state.errors
});

export default connect(mapStateToProps, { regUser })(AddUser); 
