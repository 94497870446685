import React, { Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { CContainer, CFade } from "@coreui/react";

// routes config
import routes from "../../routes";
// import { cibLinuxFoundation } from '@coreui/icons';

const loading = (
	<div className="pt-3 text-center">
		<div className="sk-spinner sk-spinner-pulse"></div>
	</div>
);

const TheContent = ({ Role, auth, setRole, socket }) => {
	if (auth.user.hasOwnProperty("admin")) {
		if (auth.user.admin) {
			setRole("superadmin");
		} else {
			setRole("admin");
		}
	} else {
		setRole("user");
	}
	return (
		<main className="c-main">
			<CContainer fluid>
				<Suspense fallback={loading}>
					<Switch>
						{routes.map((route, idx) => {
							return (
								route.component && (
									<Route
										key={idx}
										path={route.path}
										exact={route.exact}
										name={route.name}
										render={props =>
											auth.isAuthenticated === true ? (
												<CFade>
													<route.component
														{...props}
														Role={Role}
														socket={socket}
													/>
												</CFade>
											) : (
												<Redirect to="/" />
											)
										}
									/>
								)
							);
						})}
					</Switch>
				</Suspense>
			</CContainer>
		</main>
	);
};

TheContent.propTypes = {
	auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
	auth: state.auth
});

export default connect(mapStateToProps)(React.memo(TheContent));
