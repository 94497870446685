import React, { Component } from "react";
import { regUser } from "../redux/actions/authAction";
import { connect } from "react-redux";
import classNames from "classnames";
import PropTypes from "prop-types";
import isEmpty from "is-empty";
import { Link } from "react-router-dom";

import {
	CButton,
	CCard,
	CCardBody,
	CCardFooter,
	CCol,
	CContainer,
	CForm,
	CInput,
	CInputGroup,
	CInputGroupPrepend,
	CInputGroupText,
	CRow,
	CInvalidFeedback,
	CSelect,
	CFormText
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
var ObjectID = require("mongodb").ObjectID;
class Register extends Component {
	constructor(props) {
		super(props);
		this.state = {
			firstName: "",
			middleName: "guest",
			lastName: "",
			company: new ObjectID(),
			dob: "",
			gender: "",
			bloodGroup: "",
			weight: 65,
			height: 170,
			mobile: 0,
			email: "",
			medHistory: "",
			bpMed: false,
			subscription: false,
			NFC: "",
			companies: [],
			modal: false,
			errors: {},
			password: "",
			guest: true
		};
	}
	componentDidMount() {
		if (this.props.auth.isAuthenticated) {
			this.props.history.push("/dashboard");
		}
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.auth.isAuthenticated) {
			this.props.history.push("/dashboard");
		}

		console.log(nextProps);
		if (nextProps.errors) {
			this.setState({ errors: nextProps.errors });
		}
	}

	onSubmit = e => {
		e.preventDefault();

		const userData = {
			firstName: this.state.firstName,
			middleName: this.state.middleName,
			lastName: this.state.lastName,
			company: this.state.company,
			dob: this.state.dob,
			gender: this.state.gender,
			bloodGroup: this.state.bloodGroup,
			weight: this.state.weight,
			height: this.state.height,
			mobile: this.state.mobile,
			email: this.state.email,
			medHistory: this.state.medHistory,
			bpMed: this.state.bpMed,
			subscription: this.state.subscription,
			NFC: this.state.mobile,
			errors: {},
			password: this.state.password,
			guest: this.state.guest
		};

		this.props.regUser(userData);
	};

	onChange = e => {
		this.setState({ [e.target.name]: e.target.value });
		this.setState({ errors: {} });
	};
	render() {
		const { errors } = this.state;
		return (
			<div className="c-app c-default-layout flex-row align-items-center">
				<CContainer>
					<CRow className="justify-content-center">
						<CCol md="9" lg="7" xl="6">
							<CCard className="mx-4">
								<CCardBody className="p-4">
									<CForm>
										<h1>Register</h1>
										<p className="text-muted">
											Create User account
										</p>
										<CInputGroup className="mb-3">
											<CInputGroupPrepend>
												<CInputGroupText>
													<CIcon name="cil-user" />
												</CInputGroupText>
											</CInputGroupPrepend>
											<CInput
												id="firstName"
												name="firstName"
												placeholder="first name"
												value={this.state.firstName}
												onChange={this.onChange}
												invalid={errors.firstName}
												required
											/>
											{errors.firstName && (
												<CInvalidFeedback>
													{errors.firstName}
												</CInvalidFeedback>
											)}
											<CInput
												id="lastName"
												name="lastName"
												placeholder="last name"
												value={this.state.lastName}
												onChange={this.onChange}
												invalid={errors.lastName}
												required
											/>
											{errors.lastName && (
												<CInvalidFeedback>
													{errors.lastName}
												</CInvalidFeedback>
											)}{" "}
										</CInputGroup>

										<CSelect
											className="mb-3"
											custom
											name="bloodGroup"
											id="bloodGroup"
											onChange={this.onChange.bind(this)}
											required
										>
											<option value="O+">
												please select Blood Group
											</option>
											<option value="O-">O+</option>
											<option value="O-">O-</option>
											<option value="A+">A+</option>
											<option value="A-">A-</option>
											<option value="B+">B+</option>
											<option value="B-">B-</option>
											<option value="AB+">AB+</option>
											<option value="AB-">AB-</option>
										</CSelect>
										<CSelect
											className="mb-3"
											custom
											name="gender"
											id="gender"
											onChange={this.onChange.bind(this)}
											required
										>
											<option value="female">
												please select gender
											</option>
											<option value="female">
												female
											</option>
											<option value="male">male</option>
											<option value="others">
												others
											</option>
										</CSelect>
										<CInputGroup className="mb-3">
											<CInput
												id="dob"
												name="dob"
												type="date"
												placeholder="DOB"
												autoComplete="dob"
												value={this.state.dob}
												onChange={this.onChange}
											/>
										</CInputGroup>

										<CInputGroup className="mb-3">
											<CInputGroupPrepend></CInputGroupPrepend>
											<CInput
												type="number"
												id="mobile"
												name="mobile"
												placeholder="Enter Mobile Number"
												value={this.state.mobile}
												onChange={this.onChange}
												invalid={errors.mobile}
												required
											/>
											{errors.mobile && (
												<CInvalidFeedback>
													{errors.mobile}
												</CInvalidFeedback>
											)}
										</CInputGroup>
										<CInputGroup className="mb-3 form-control-warning">
											<CInputGroupPrepend>
												<CInputGroupText>
													<CIcon name="cil-user" />
												</CInputGroupText>
											</CInputGroupPrepend>
											<CInput
												placeholder="email"
												type="email"
												name="email"
												value={this.state.email}
												onChange={this.onChange}
												invalid={errors.email}
											/>
											{errors.email && (
												<CInvalidFeedback>
													{errors.email}
												</CInvalidFeedback>
											)}
										</CInputGroup>
										<CInputGroup className="mb-3">
											<CInputGroupPrepend>
												<CInputGroupText>
													<CIcon name="cil-lock-locked" />
												</CInputGroupText>
											</CInputGroupPrepend>
											<CInput
												type="password"
												id="password"
												name="password"
												placeholder="Password"
												value={this.state.password}
												onChange={this.onChange}
												invalid={errors.password}
												required
											/>
											{errors.password && (
												<CInvalidFeedback>
													{errors.password}
												</CInvalidFeedback>
											)}
											<CFormText className="help-block">
												Create password
											</CFormText>
										</CInputGroup>

										<CButton
											color="success"
											block
											onClick={this.onSubmit}
										>
											Create Account
										</CButton>
									</CForm>
								</CCardBody>
								<CCardFooter className="p-4"></CCardFooter>
							</CCard>
						</CCol>
					</CRow>
				</CContainer>
			</div>
		);
	}
}
Register.propTypes = {
	regUser: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
	errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
	auth: state.auth,
	errors: state.errors
});

export default connect(mapStateToProps, { regUser })(Register);
