import React, { Component } from "react";
import { logUser } from "../redux/actions/authAction";
import { connect } from "react-redux";
import classNames from "classnames";
import PropTypes from "prop-types";
import isEmpty from "is-empty";
import { Link } from "react-router-dom";

import {
	CButton,
	CCard,
	CCardBody,
	CCardGroup,
	CCol,
	CContainer,
	CForm,
	CInput,
	CInputGroup,
	CInputGroupPrepend,
	CInputGroupText,
	CInvalidFeedback,
	CRow
} from "@coreui/react";
import CIcon from "@coreui/icons-react";

class Login extends Component {
	constructor(props) {
		super(props);
		this.state = {
			email: "",
			password: "",
			errors: {}
		};
	}
	componentDidMount() {
		if (this.props.auth.isAuthenticated) {
			// console.log("hi")
			if (this.props.auth.user.school) {
				// console.log("hii")
				this.props.history.push("/schDashboard");
			} else this.props.history.push("/dashboard");
		}
	}

	componentWillReceiveProps(nextProps) {
		// console.log("hi")
		if (nextProps.auth.isAuthenticated) {
			console.log(nextProps.auth.user.school);
			if (nextProps.auth.user.school) {
				//console.log("hii")
				this.props.history.push("/schDashboard");
			} else this.props.history.push("/dashboard");
		}

		console.log(nextProps);
		if (nextProps.errors) {
			this.setState({ errors: nextProps.errors });
		}
	}

	onSubmit = e => {
		e.preventDefault();

		const userData = {
			email: this.state.email,
			password: this.state.password
		};

		this.props.logUser(userData);
	};

	onChange = e => {
		this.setState({ [e.target.name]: e.target.value });
		this.setState({ errors: {} });
	};

	render() {
		const { errors } = this.state;

		return (
			<div className="c-app c-default-layout flex-row align-items-center">
				<CContainer>
					<CRow className="justify-content-center">
						<CCol md="6">
							<CCardGroup>
								<CCard className="p-4">
									<CCardBody>
										<CForm onSubmit={this.onSubmit}>
											<h1>Login</h1>
											<p className="text-muted">
												Sign In to your account
											</p>
											<CInputGroup className="mb-3 form-control-warning">
												<CInputGroupPrepend>
													<CInputGroupText>
														<CIcon name="cil-user" />
													</CInputGroupText>
												</CInputGroupPrepend>
												<CInput
													placeholder="email"
													type="email"
													name="email"
													value={this.state.email}
													onChange={this.onChange}
													invalid={errors.email}
												/>
												{errors.email && (
													<CInvalidFeedback>
														{errors.email}
													</CInvalidFeedback>
												)}
											</CInputGroup>
											<CInputGroup className="mb-4">
												<CInputGroupPrepend>
													<CInputGroupText>
														<CIcon name="cil-lock-locked" />
													</CInputGroupText>
												</CInputGroupPrepend>
												<CInput
													placeholder="Password"
													name="password"
													type="password"
													value={this.state.password}
													onChange={this.onChange}
													invalid={errors.password}
												/>
												{errors.password && (
													<CInvalidFeedback>
														{errors.password}
													</CInvalidFeedback>
												)}
											</CInputGroup>
											<CRow>
												<CCol xs="6">
													<CButton
														color="primary"
														className="px-4"
														type="submit"
													>
														Login
													</CButton>
												</CCol>
												<CCol
													xs="6"
													className="text-right"
												>
													<CButton
														color="link"
														className="px-0"
													>
														Forgot password?
													</CButton>
												</CCol>
											</CRow>
										</CForm>
									</CCardBody>
								</CCard>
								<CCard
									className="text-white bg-primary py-5 d-md-down-none"
									style={{ width: "44%" }}
								>
									<CCardBody className="text-center">
										<div>
											<h2>Sign up</h2>
											<p>
												ABHAYPARIMITI - Fearless
												Perimiter
											</p>
											<Link to="/register">
												<CButton
													color="primary"
													className="mt-3"
													active
													tabIndex={-1}
												>
													Register Now!
												</CButton>
											</Link>
										</div>
									</CCardBody>
								</CCard>
							</CCardGroup>
						</CCol>
					</CRow>
				</CContainer>
			</div>
		);
	}
}

Login.propTypes = {
	logUser: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
	errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
	auth: state.auth,
	errors: state.errors
});

export default connect(mapStateToProps, { logUser })(Login);
